import { z } from 'zod';
import { networkSchema } from '@zealy/utils';
export const getUserPathSchema = z.object({
    id: z.string().uuid(),
});
export const getUserInputSchema = getUserPathSchema;
const baseOutputSchema = z.object({
    id: z.string(),
    displayedInformation: z.array(z.string()),
    name: z.string().nullable(),
    avatar: z.string().nullable(),
    goal: z.string().nullable(),
    createdAt: z.string().datetime().nullable(),
    updatedAt: z.string().datetime().nullable(),
    restoredAt: z.string().datetime().nullable(),
    deletedAt: z.string().datetime().nullable(),
    cheatStatus: z.enum(['ok', 'warning', 'restricted']).optional(),
    restrictedUntil: z.string().datetime().nullable().optional(),
    cheatScore: z.number().optional(),
    lastRestrictedAt: z.string().datetime().nullable().optional(),
});
export const getUserPublicOutputSchema = z
    .object({
    isMe: z.literal(false),
    discordHandle: z.string().nullable().optional(),
    twitterUsername: z.string().nullable().optional(),
    addresses: z.record(z.string()).optional(),
})
    .merge(baseOutputSchema);
export const getUserPrivateOutputSchema = z
    .object({
    isMe: z.literal(true),
    discordHandle: z.string().nullable(),
    tiktokUsername: z.string().nullable(),
    tiktokProfileLink: z.string().nullable(),
    tiktokDisplayName: z.string().nullable(),
    tiktokFollowersCount: z.number().nullable(),
    twitterUsername: z.string().nullable(),
    addresses: z.record(z.string()),
    verifiedAddresses: z.record(networkSchema, z.string()),
    ethWallet: z.string().optional(),
    city: z.string().nullable(),
    country: z.string().nullable(),
    twitterFollowersCount: z.number().nullable(),
    tweetCount: z.number().nullable(),
    interests: z.array(z.string()).nullable(),
    referrer: z.string().nullable(),
    guilds: z
        .array(z.object({
        id: z.string(),
        name: z.string(),
        icon: z.string().nullable(),
        owner: z.boolean(),
    }))
        .nullable(),
    socialAccounts: z
        .array(z.object({
        id: z.string(),
        name: z.string(),
        type: z.string(),
        verified: z.boolean(),
    }))
        .nullable(),
    accounts: z
        .array(z.object({
        id: z.string(),
        userId: z.string(),
        tokenStatus: z.string(),
        verified: z.boolean().nullable(),
        accountType: z.string().nullable(),
        authentification: z.string(),
        createdAt: z.string().datetime().nullable(),
        updatedAt: z.string().datetime().nullable(),
    }))
        .optional(),
})
    .merge(baseOutputSchema);
export const getUserOutputSchema = z.discriminatedUnion('isMe', [
    getUserPublicOutputSchema,
    getUserPrivateOutputSchema,
]);
