import { router } from '../../utils';
import { createQuest } from './createQuest';
import { generateRedirectUrlForApiQuestTask } from './generateRedirectUrlForApiQuestTask';
import { getQuestVoteFeed } from './getQuestVoteFeed';
import { getZealyConnectStatus } from './getZealyConnectStatus';
import { listRecommendedQuests } from './listRecommendedQuests';
import { listTaskValidations } from './listTaskValidations';
import { testApiQuestEndpoint } from './testApiQuestEndpoint';
import { testClaimQuest } from './testClaimQuest';
import { verifyZealyConnectCallback } from './verifyZealyConnectCallback';
import { voteEvents } from './voteEvents';
const communityQuestsContract = router({
    testApiQuestEndpoint: testApiQuestEndpoint,
    createQuest: createQuest,
    testClaimQuest: testClaimQuest,
    listTaskValidations: listTaskValidations,
    generateRedirectUrlForApiQuestTask: generateRedirectUrlForApiQuestTask,
    verifyZealyConnectCallback: verifyZealyConnectCallback,
    getZealyConnectStatus: getZealyConnectStatus,
    getQuestVoteFeed,
    voteEvents,
}, {
    pathPrefix: '/communities/:subdomain/quests',
});
const allQuestsContract = router({
    listRecommendedQuests,
}, {
    pathPrefix: '/quests',
});
export const questContract = router({
    ...communityQuestsContract,
    ...allQuestsContract,
});
