import { ofetch } from 'ofetch';
import { config } from './config';
import { clientFactory, forwardedCookie } from './utils';
const $fetch = ofetch.create({
    baseURL: config.baseURL,
    headers: config.headers,
    onRequest(context) {
        context.options.baseURL = config.baseURL;
        const cookie = forwardedCookie();
        const apiKey = context.options.apiKey;
        context.options.headers = {
            ...context.options.headers,
            ...config.headers,
            ...(apiKey ? { 'X-Api-Key': apiKey } : {}),
            ...(cookie ? { cookie } : {}),
        };
    },
    credentials: 'include',
});
/**
 * HTTP API Fetch instance
 * @param request
 * @param options
 * @returns
 */
function __api(request, options) {
    return $fetch(request, options);
}
export const api = clientFactory(__api, $fetch);
