import { z } from 'zod';
import { reviewSchema } from '../reviews';
const feedItem = reviewSchema.merge(z.object({
    voteStatus: z.enum(['upvote', 'downvote']).optional(),
}));
export const getQuestVoteFeedOutputSchema = z.object({
    items: z.array(feedItem),
    nextCursor: z.string().nullable(),
});
export const getQuestVoteFeedPathSchema = z.object({
    subdomain: z.string(),
    questId: z.string().uuid(),
    rewardId: z.string().uuid(),
});
export const getQuestVoteFeedQuerySchema = z.object({
    limit: z.coerce.number().int().positive().max(1000).default(20),
    cursor: z.string().optional().nullable(),
});
