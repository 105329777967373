import Cookies from 'js-cookie';
import { apiV2 } from '../services';
import { api } from '../services/api';
import { config } from '../services/config';
import { getMe } from '../services/utils';
export const isUserConnected = () => !!Cookies.get('user_metadata');
export const getUser = async (id) => {
    const userId = id === 'me' ? getMe() : id;
    const res = await apiV2.user.get({
        params: { id: userId },
        cache: id === 'me' ? 'no-store' : undefined,
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getUser failed');
    }
};
export const getCommunityMember = async (id, subdomain) => {
    const userId = id === 'me' ? getMe() : id;
    const res = await apiV2.communityMember.get({
        params: { subdomain: subdomain, userId: userId },
        cache: 'no-store',
    });
    if (res.status === 200) {
        return res.body;
    }
    else {
        // handle error here
        throw new Error('getCommunityMember failed');
    }
};
export const createUser = async (body) => {
    const res = await apiV2.user.create({
        body,
    });
    if (res.status === 200) {
        return res.body;
    }
    if (res.status === 400) {
        throw new Error(res.body.message);
    }
    throw new Error('createUser failed');
};
export const createUserV2 = async (body) => {
    const res = await apiV2.user.createV2({
        body,
    });
    if (res.status === 200) {
        return res.body;
    }
    if (res.status === 400) {
        throw new Error(res.body.message);
    }
    throw new Error('createUser failed');
};
export const updateAuthenticatedUser = async (data) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        if (value) {
            formData.append(key, value);
        }
    });
    const response = await api.patch('/users/me', {
        body: formData,
    });
    return response;
};
export const deleteAuthenticatedUser = async () => {
    return api.delete('/users/me');
};
export const deleteUserAccount = async (userAccountId) => {
    const response = await api.delete(`/users/me/accounts/${userAccountId}`);
    return response;
};
export const getActivity = async ({ userId, subdomain, page = 0, limit = 40, options, }) => {
    const { activity } = await api(`/communities/${subdomain}/users/${userId}/activity?page=${page}&limit=${limit}`, options);
    return {
        results: activity,
        nextPage: page + 1,
        hasNextPage: activity.length >= limit,
    };
};
export const getInvites = async ({ userId, subdomain, status, invitesQuestId, pageParam = 1, limit = 100, }) => {
    const res = await apiV2.communityMember.listInvites({
        params: {
            subdomain,
            userId,
        },
        query: {
            status,
            invitesQuestId,
            page: pageParam,
            page_size: limit,
        },
    });
    if (res.status === 200) {
        const { data } = res.body;
        return {
            results: data,
            nextPage: pageParam + 1,
            hasNextPage: data.length === limit,
        };
    }
    else {
        // handle error here
        throw new Error('listInvites fail');
    }
};
export const getInvitesCount = async ({ userId, subdomain, status, invitesQuestId, }) => {
    const res = await apiV2.communityMember.countInvites({
        params: {
            subdomain,
            userId,
        },
        query: {
            status,
            invitesQuestId,
        },
    });
    if (res.status === 200) {
        const { count } = res.body;
        return {
            count,
        };
    }
    else {
        // handle error here
        throw new Error('getInvitesCount fail');
    }
};
export const banUser = async (subdomain, userId, body) => {
    const res = await apiV2.communityMember.ban({
        params: {
            subdomain,
            userId,
        },
        body,
    });
    if (res.status !== 200) {
        // handle error here
        throw new Error('banUser failed');
    }
};
export const unBanUser = async (subdomain, userId) => api.delete(`/communities/${subdomain}/users/${userId}/ban`);
export const updateUserRole = async (userId, subdomain, role) => api.post(`/communities/${subdomain}/members/change-role`, {
    body: {
        role,
        memberId: userId,
    },
});
export const uploadUserFile = ({ formData, onProgress, signal, url = `${config.baseURL}/files`, }) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('post', url, true);
        xhr.withCredentials = true;
        xhr.responseType = 'json';
        xhr.upload.onprogress = onProgress;
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                }
                else {
                    reject(xhr.response);
                }
            }
        };
        signal?.addEventListener('abort', () => {
            xhr.abort();
            reject();
        });
        xhr.onerror = reject;
        xhr.send(formData);
    });
};
export const deleteUserFile = (url) => api.delete(`${config.baseURL}/files`, {
    body: {
        url,
    },
});
export const getUserByField = async ({ field, value, }) => {
    const { status, body } = await apiV2.user.getUserByField({
        query: {
            [field]: value,
        },
    });
    if (status !== 200) {
        throw new Error();
    }
    return body;
};
