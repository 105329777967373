import { router } from '../../utils';
import { banMember } from './banMember';
import { getMember } from './getMember';
import { getMemberBySocialId } from './getMemberBySocialId';
import { getReferralCode } from './getReferralCode';
import { listInvites } from './listInvites';
export const publicCommunityMemberContract = router({
    ban: banMember,
    getMember: getMember,
    getMemberBySocialId: getMemberBySocialId,
    getReferralCode: getReferralCode,
    listInvites: listInvites,
}, {
    pathPrefix: '/communities/:subdomain/users',
});
