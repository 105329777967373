import { voteEventsInputSchema, voteEventsOutputSchema, voteEventsPathSchema, } from '@zealy/schemas';
import { mutation } from '../../utils';
export const voteEvents = mutation({
    method: 'POST',
    path: '/:questId/rewards/:rewardId/votes',
    pathParams: voteEventsPathSchema,
    body: voteEventsInputSchema,
    responses: {
        201: voteEventsOutputSchema,
    },
});
