import { router } from '../../utils';
import { listCommunity } from './listCommunity';
import { getCommunity } from './getCommunity';
import { updateCommunitySettingsPartnerships } from './updateCommunitySettingsPartnerships';
export const communityContract = router({
    list: listCommunity,
    get: getCommunity,
    updateSettings: updateCommunitySettingsPartnerships,
}, {
    pathPrefix: '/communities',
});
