import { initClient, tsRestFetchApi } from '@ts-rest/core';
import { contract } from '@zealy/contracts';
import { configV2 } from './config';
import { forwardedCookie } from './utils';
export const apiV2 = initClient(contract, {
    baseUrl: configV2.baseURL,
    baseHeaders: configV2.headers,
    credentials: 'include',
    // implement https://ts-rest.com/docs/core/custom#extra-query-arguments to be able to use next (revalidate and tags) that tsRestFetchApi already support
    api: async ({ headers, _next: next, ...args }) => {
        const cookie = forwardedCookie();
        return tsRestFetchApi({
            ...args,
            headers: {
                ...headers,
                ...(cookie ? { cookie } : {}),
            },
            next,
        });
    },
});
